import * as React from 'react';
import { sizes } from '@sharefiledev/antd-config';
import {
	ClientDashboardDrawerCommentsTabExtensionParams,
	clientDashboardExtensionSlots,
} from '@sharefiledev/client-dashboard';
import { Tab, WorkflowsIcon } from '@sharefiledev/flow-web';
import { requestListExtensionSlots } from '@sharefiledev/request-list-pilet';
import { RID } from '@sharefiledev/sfplugin-resource-id';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { QueryClient } from '@tanstack/react-query';
import { ConversationViewerBlockParams } from './Blocks/ConversationViewer';
import { configureConversationHttp } from './ConversationHttp';
import {
	AsyncLazyClientDashboardTabExtension,
	AsyncLazyCommentsTabExtension,
	AsyncLazyConversationsTileExtension,
	AsyncLazyConversationsWrapper,
	AsyncLazyConversationTabWrapper,
	AsyncLazyConversationViewerBlock,
	AsyncLazyDemoPage,
	AsyncLazyItemRowMetadata,
} from './Extensions';
import { setLogger } from './logger';
import { conversationsExtensionSlots, ConversationsTileParams } from './sdk';

const conversationTabWrapperStyle = {
	display: 'flex',
	paddingTop: '15px',
	margin: 'auto',
	maxWidth: '700px',
	maxHeight: 'calc(100% - 15px)',
};

export function setup(app: PiletApi) {
	setLogger(app.sf.getLogger());
	const queryClient = new QueryClient();

	app.registerExtension(requestListExtensionSlots.itemDetailsTabs, ({ params }) => {
		return (
			<AsyncLazyConversationTabWrapper
				app={app}
				queryClient={queryClient}
				tabContext={params.tabContext}
				resourceId={params.resourceId}
				containerName={params.containerName}
				actorIds={params.actorIds}
				resourceTitle={params.resourceTitle}
				pendoTrackingNamespace={params.pendoTrackingNamespace}
				enableFilters={false}
				style={{
					display: 'flex',
					justifyContent: 'center',
					margin: 'auto',
					marginLeft: '1px',
					padding: `${sizes.base} ${sizes.SM}`,
				}}
			/>
		);
	});

	app.registerExtension(requestListExtensionSlots.itemRowMetadata, ({ params }) => {
		return <AsyncLazyItemRowMetadata app={app} client={queryClient} params={params} />;
	});

	app.registerExtension(
		'urn:sfextslot:projects-pilet:tabs:conversations',
		({ params }) => {
			return (
				<AsyncLazyConversationTabWrapper
					app={app}
					queryClient={queryClient}
					tabContext={params.tabContext}
					resourceId={params.projectUri}
					pendoTrackingNamespace={params.pendoTrackingNamespace}
					enableFilters
					style={conversationTabWrapperStyle}
				/>
			);
		}
	);

	app.registerExtension(conversationsExtensionSlots.projectTab, ({ params }) => {
		return (
			<AsyncLazyConversationTabWrapper
				app={app}
				queryClient={queryClient}
				tabContext={params.tabContext}
				resourceId={params.projectUri}
				pendoTrackingNamespace={params.pendoTrackingNamespace}
				enableFilters={params.enableFilters ?? true}
				style={conversationTabWrapperStyle}
			/>
		);
	});

	app.registerExtension<ConversationsTileParams>(
		conversationsExtensionSlots.tile,
		({ params }) => {
			return (
				<AsyncLazyConversationsTileExtension
					app={app}
					queryClient={queryClient}
					params={params}
				/>
			);
		}
	);

	app.registerExtension<ClientDashboardDrawerCommentsTabExtensionParams>(
		clientDashboardExtensionSlots.drawerCommentsTab,
		({ params }) => {
			return (
				<AsyncLazyClientDashboardTabExtension
					app={app}
					queryClient={queryClient}
					params={params}
				/>
			);
		}
	);

	app.registerExtension(clientDashboardExtensionSlots.commentsTab, ({ params }) => {
		return (
			<AsyncLazyCommentsTabExtension
				app={app}
				queryClient={queryClient}
				params={params}
			/>
		);
	});

	app.registerExtension(
		clientDashboardExtensionSlots.drawerCommentsContent,
		({ params }) => {
			return (
				<AsyncLazyConversationsWrapper
					app={app}
					queryClient={queryClient}
					resourceId={params.resourceUri}
					actorIds={params.actorIds}
					resourceTitle={params.resourceTitle}
					containerName={params.containerName}
					enableFilters={false}
					isClientSide
				/>
			);
		}
	);

	app.registerExtension<ConversationViewerBlockParams>(
		'urn:block:conversations:viewer',
		({ params }) => (
			<AsyncLazyConversationViewerBlock
				app={app}
				params={params}
				queryClient={queryClient}
			/>
		)
	);

	app.registerExtension('sf-conversations-empty', () => {
		return <h1>Select a task to view its conversations.</h1>;
	});

	app.sf.registerViewEngineResourceURLRewrite({
		resourceType: 'conversation_msg',
		defaultPath: 'projects/{projectId}/Comments',
		clientPath: '/client?taskRID={resourceId}',
		callback: async (rid: string) => {
			return {
				projectId: RID.parse(rid).findEntityType('project').entityId,
				resourceId: RID.parse(rid).parent().parent(),
			};
		},
	});

	app.sf.registerViewEngineResourceURLRewrite({
		resourceType: 'conversation',
		defaultPath: '{resourcePath}',
		clientPath: '/client?taskRID={resourceId}',
		callback: async (rid: string) => {
			const parentRID = RID.parse(rid).parent();
			const parentResolution = await app.sf.getViewEngineResourceURLRewrite(
				parentRID.toString()
			);

			return {
				resourceId: parentRID,
				resourcePath: parentResolution.defaultPath,
				...parentResolution.dataContract,
			};
		},
	});

	if (process.env.NODE_ENV === 'development') {
		if (typeof app.meta.config.apiOrigin === 'string') {
			configureConversationHttp(config => {
				config.origin = app.meta.config.apiOrigin + '/io/conversations';
			});
		}

		app.sf.registerLeftNavComponent({
			href: '/conversations',
			title: () => 'Conversations',
			icon: WorkflowsIcon,
			weight: 50,
		});

		app.registerExtension('devTab', ({ params }) => {
			return (
				<AsyncLazyConversationTabWrapper
					app={app}
					queryClient={queryClient}
					tabContext={params.tabContext}
					resourceId={params.resourceId}
					pendoTrackingNamespace={params.pendoTrackingNamespace}
					enableFilters={false}
					style={{
						padding: '10px',
						display: 'flex',
						flexDirection: 'column',
						margin: 'auto',
						maxWidth: '500px',
						maxHeight: '700px',
					}}
				/>
			);
		});

		app.registerExtension('clickme-tab', () => {
			const clickMe = 'Click Me!';
			return <Tab value={clickMe} label={clickMe} />;
		});

		app.registerPage('/conversations', () => <AsyncLazyDemoPage piletApi={app} />);
	}
}
