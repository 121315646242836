import { TabContextProps } from '@sharefiledev/flow-web';
import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package
import { BlockParams } from '@sharefiledev/solution-view-engine';

const prefix = 'urn';
const namespace = 'sfextslot';
const owner = 'conversations-pilet';
const blockNamespace = `${prefix}:sfblock:conversations` as const;

export const conversationsExtensionSlots = {
	badge: `${prefix}:${namespace}:${owner}:badge` as const,
	clientViewTab: `${prefix}:${namespace}:${owner}:clientView:tab` as const,
	conversationsBlock: `${blockNamespace}:viewer` as const,
	drawerContent: `${prefix}:${namespace}:${owner}:drawerTab:content` as const,
	drawerTab: `${prefix}:${namespace}:${owner}:drawerTab` as const,
	projectTab: `${prefix}:${namespace}:${owner}:projectTab` as const,
	tab: `${prefix}:${namespace}:${owner}:tab` as const,
	tile: `${prefix}:${namespace}:${owner}:tile` as const,
};

export interface BaseConversationsParams {
	parentUri: string;
}

export interface ConversationsClientViewTabParams extends BaseConversationsParams {
	isSelected: boolean;
}

export interface ConversationsTabParams extends BaseConversationsParams {
	tabContext: TabContextProps;
}

export interface ConversationsProjectTabParams {
	projectUri: string;
	tabContext: TabContextProps;
	pendoTrackingNamespace?: string;
	enableFilters?: boolean;
}
export interface ConversationsTileParams extends BaseConversationsParams {
	getTabPath: (tabKey: string) => string;
	piletSortWeight?: number;
	StyledTileWrapper: React.ElementType;
}

type ConversationViewerBlockParams = {
	containerRID: string;
	enableFilters: boolean;
	isTile: boolean;
} & BlockParams;

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		[conversationsExtensionSlots.badge]: BaseConversationsParams;
		[conversationsExtensionSlots.clientViewTab]: ConversationsClientViewTabParams;
		[conversationsExtensionSlots.conversationsBlock]: ConversationViewerBlockParams;
		[conversationsExtensionSlots.drawerContent]: BaseConversationsParams;
		[conversationsExtensionSlots.drawerTab]: BaseConversationsParams;
		[conversationsExtensionSlots.projectTab]: ConversationsProjectTabParams;
		[conversationsExtensionSlots.tab]: ConversationsTabParams;
		[conversationsExtensionSlots.tile]: ConversationsTileParams;
	}
}

export interface ItemRowMetadataExtensionParams {
	resourceId: string;
	onIconClick?: () => void;
	uiConfiguration?: {
		showBadgeInline?: boolean;
		hideTooltip?: boolean;
	};
}
